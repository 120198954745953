
.shop.mosaico .product.mosaico:hover {
	border: 1 solid transparent !important;
}
.product.mosaico {
	cursor: pointer;
	margin: 0 !important;
	padding: 0 !important;
	background-color: white;
}
.shop.mosaico .col-md-3 {
	padding: 9px;
}

.subcategoria_h2 {
	margin-bottom: 10px;
	color: #303030;
	font-weight: lighter;
	letter-spacing: 4px;
	border-bottom: 1px solid #303030;
	text-transform: uppercase;
}
.product-description-2 .p-title {
	line-height: 1.2;
	color: #75513b;
	padding-top: 5px;
	/* text-transform: uppercase; */
	font-weight: 500;
	font-size: 14px;
}
.product-description-2 .p-recomendado {
	text-decoration: line-through;
	font-weight: 100;
	font-size: 0.9em;
	position: relative;
	right: 0;
	text-align: right;
}

.product-description-2 .p-description {
	margin-top: -5px;
	color: #696f6f;
	line-height: 1.2;
	font-weight: 100;
	font-size: 1em;
}

.product-description-2 .p-price {
	margin-top: -10px;
	font-weight: bold;
	font-size: 1.3em;
	color: #d03737;
	position: relative;
	right: 0;
	text-align: right;
}

.product-description-2 .p-envio {
	position: relative;
	margin-top: -3px;
	/* border:1px solid #594539; */
	padding: 3px 0;
	border-radius: 2px;
	color: black;
	font-size: 13px;
	font-weight: 300;
}
.product-description-2 .p-novedad {
	position: relative;
	top: -3px;
	background-color: #2e800e;
	padding: 4px;
	border-radius: 2px;
	color: white;
	font-size: 10px;
	font-weight: lighter;
}

.product-description-2 .p-oferta {
	position: relative;
	top: -3px;
	background-color: #d03737;
	padding: 4px;
	border-radius: 2px;
	color: white;
	font-size: 10px;
	font-weight: lighter;
}
#mainMenu > ul > li > a {
	font-size: 11px !important;
	padding: 4px 10px !important;
}
#header #buscar {
	font-size: 15px !important;
	padding: 2px !important;
}
#header #shopping-cart {
	top: -9px;
	font-size: 15px !important;
	padding: 2px !important;
	margin: 1px !important;
}

#header-wrap {
	height: 55px !important;
}
.truncate {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
#header {
	height: 55px !important;
}
#mainMenu {
	margin-top: 2px !important;
}
#slider {
	top: 0px !important;
}
#logo img {
	max-height: 60px;
	margin-top: 0px;
	margin-bottom: 0;
	padding: 0 !important;
}
.cuenta_atras_xs {
	position: relative;
	top: -30px;
	margin: 0 0 5px 0;
	text-align: center;
	background: #d03737;
	color: #fff;
	padding: 5px 0 8px;
	line-height: 1.4em;
}
.cuenta_atras {
	width: 100%;
	height: 30px;
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
	background: #d03737;
	color: #fff;
	position: absolute;
	padding-top: 3px;
	top: 55px;
	left: 0;
}

@media (max-width: 991px) {
	.nav-main-menu-responsive button.extra i {
		top: 6px !important;
	}
	.nav-main-menu-responsive button i {
		font-size: 20px;
	}
	.nav-main-menu-responsive button {
		float: right;
		top: 0px !important;
		position: relative;
	}
	.nav-main-menu-responsive {
		width: 50%;
		float: right;
		line-height: 40px !important;
		height: 40px !important;
		margin-right: 10px;
		margin-top: -2px;
	}
	#header.header-fullwidth #header-wrap .container {
		padding: 0px !important;
		width: 100% !important;
	}
	#header .nav-main-menu-responsive #shopping-cart {
		float: right;
		height: 80px;
		margin: 10px 17px  10px 3px !important;
		position: relative;
		top: 0px;
	}
	#header #shopping-cart a i {
		width: auto;
		float: right;
		font-size: 26px !important;
		top: -16px;
		position: relative;
	}
}
@media (max-width: 1100px) {
	.subcategoria_h2{
		font-size:18px;
	}
	#header {
		min-height: 54px !important;
		height: 53px !important;
	}
	#logo {
		margin: 20px 0px 0px 0;
	}
	#logo img {
		max-height: 45px;
		margin-top: -13px;
		margin-left: 10px;
		margin-bottom: 0;
		padding: 0 !important;
	}
	
}

@media (max-width: 767px) {
	
	#slider{	
		margin-top: -40px;
	}
	.cuenta_atras{
		top: 48px;
		height: 25px;
		padding-top: 2px;
		font-size: 9px;
	}

	.col-xs-9 {
		width: 66% !important;
		clear: none;
	}
	.col-xs-3 {
		width: 33% !important;
		clear: none;
	}
	.col-xs-6 {
		width: 49% !important;
		clear: none;
	}
	.shop.mosaico .col-xs-6 {
		margin-top: 0;
	}
	.shop.mosaico .col-md-3 {
		padding: 1px;
	}

	.product-description-2 .p-title {
		line-height: 1;
		font-size: 13px;
	}
	.product-description-2 .p-envio {
		margin-top: -7px;
		font-size: 8px;
	}
	.product-description-2 .p-price {
		font-size: 1em;
	}
	.product-description-2 .p-recomendado {
		font-size: 0.8em;
	}
	.product-description-2 .p-novedad,
	.product-description-2 .p-oferta {
		top: -10px;
		font-size: 8px;
	}
	.product-description-2 .p-description {
		display: block;
		margin-top: -10px !important;
		margin-bottom: 5px !important;
	}
}
.product.carrito .product-description .product-price {
	margin-top: -4px !important;
}
.product.carrito .product-description h3 {
	color: #696f6f;
	text-transform: uppercase;
	font-weight: 100;
}
.product.carrito.seleccionado .product-description h3 {
	color: #fff;
}
.product.mosaico.carrito.complemento {
	border: 1px solid whitesmoke;
}
.product.mosaico.carrito.complemento:hover {
	border: 1px solid #d03737 !important;
}




#product-page .product-description-2 .p-title {
	color: #75513b;
	padding-top: 5px;
	/* text-transform: uppercase; */
	font-weight: 500;
	font-size: 28px;
}
#product-page .product-description-2 .p-recomendado {
	text-decoration: line-through;
	font-weight: 100;
	font-size: 1.2em;
	position: relative;
	right: 0;
	text-align: right;
	
}
@media (max-width: 767px) {
	#product-page .product-description-2 .p-title {
		font-size: 20px !important;	
	}
	#product-page .product-description-2 .p-price {
		font-size: 1.5em !important;	
	}
	#product-page .product-description-2 .p-recomendado {
		font-size: 1em !important;	
	}

}


#product-page .product-description-2 .p-description {
	color: #696f6f;
	line-height: 1.2;
	font-weight: 100;
	font-size: 1.2em;
	margin-bottom: 10px !important;
}

#product-page .product-description-2 .p-price {
	margin-top: -3px;
	font-weight: bold;
	font-size: 1.8em;
	color: #d03737;
	position: relative;
	right: 0;
	text-align: right;
}

#product-page .product-description-2 .p-envio {
	position: relative;
	top: -3px;
	/* border:1px solid #594539; */
	padding: 3px 0;
	border-radius: 2px;
	color: black;
	font-size: 16px;
	font-weight: 300;
}
#product-page .product-description-2 .p-oferta {
	position: relative;
	top: 3px;
	background-color: #d03737;
	padding: 4px;
	border-radius: 2px;
	color: white;
	font-size: 10px;
	font-weight: lighter;
}
#product-page label {
	font-size: 1.3em;
    font-weight: lighter;
}
#product-page .container_options{
	display: flex;
	align-items: flex-start;
}
#product-page .opcion {
	margin-right: 2px;	
}
#product-page .opcion.opciones-3 {
	width: 33%;
}
#product-page .opcion p{
	font-weight: lighter;
	font-size: 17px;
}
#product-page .opcion .precio{
	font-weight: 600;
	font-size: 16px;
}
#product-page .opcion .opt_description {
	margin-top: 3px;;
	min-height: 30px;
	color: #696f6f;
	line-height: 0.9 !important;
	font-weight: 100;
	font-size: 1em;
}
section.cuidados_composicion{
	padding:0;

}
section.cuidados_composicion .composicion .item  {
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    margin-bottom: 10px;
}
section.cuidados_composicion .composicion .item img {
    margin-top: 15px;
}
section.cuidados_composicion .composicion .item div.nombre{
	color: #75513b;
	margin-top: -10px;
	line-height: 1.2;
	font-weight: 100;
    text-transform: uppercase;
	font-size: 1.2em;
}
section.cuidados_composicion .composicion .item div.cantidad{
	float: right;
}
section.cuidados_composicion .composicion .item div.resumen{
	color: #696f6f;
	line-height: 1.2;
	font-weight: 100;
	font-size: 1em;
}

section.cuidados_composicion .cuidados .item div.nombre{
	color: #75513b;
	margin-top: -10px;
	line-height: 1.2;
	font-weight: 100;
    text-transform: uppercase;
	font-size: 1.2em;
}

section.cuidados_composicion .cuidados .item div.descripcion{
	color: #696f6f;
	font-weight: 100;
	font-size: 0.9em;
}

@media (max-width: 1100px) {
}
@media (max-width: 767px) {
	#product-page{
		margin-top: -40px;
	}
}
.container_points{
	display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
	position: relative;
	top:-35px;
	text-align:center;
	z-index: 536;
}
.demo-controls {
    display: flex;
    overflow-x: scroll;
    width: 450px;
}

.point-images{
	cursor:pointer;
	height:24px;
	width:24px;
	margin:0 4px;
	border-radius: 50%;
	background-color: lightgray;
	border: 1px solid gainsboro;
}
.point-images-selected{
	background-color: gray;
}

.swipe {
	overflow: hidden;
	visibility: hidden;
	position: relative;
	width: 500px;
  }
  .swipe-wrap {
	overflow: hidden;
	position: relative;
  }
  .swipe-wrap > div {
	float: left;
	width: 100%;
	position: relative;
  }

/* Slideshow container */
.slideshow-container {
  width: 300px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}


/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

#product-page h1.p-title,
#product-page h2.p-envio {
	margin-bottom: 0;
}

#product-page .contacto_producto label {
	font-size: 13px;
	font-weight: bold;
}

#product-page .contacto_producto .checkbox label {
	font-weight: normal;
}
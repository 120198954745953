@import url("http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900");

body,
html {
	font-family: "Roboto", sans-serif !important;
}
h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
	font-family: "Roboto", sans-serif !important;
}
#header,
#header-wrap,
#header.header-sticky:not(.header-static) #header.header-sticky:not(.header-static) .nav-main-menu-responsive,
#header .side-panel-button,
#mainMenu > ul > li > a,
#mainMenu ul.main-menu {
	height: 60px !important;
}
#mainMenu ul.main-menu {
	height: auto !important;
}
#mainMenu > ul > li > a,
#header #buscar {
	line-height: 40px !important;
}
#shopping-cart > a > i {
	line-height: 58px !important;
}
#header #shopping-cart .shopping-cart-items {
	top: 8px !important;
}
body.wide #topbar.topbar-fullwidth > .container {
	width: 100% !important;
}

#header #header-wrap .navigation-wrap.navigation-active {
	height: calc(100vh - 84px) !important;
	overflow-y: scroll;
}

.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name {
	padding-top: 0;
	padding-bottom: 0;
}

.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name > div {
	height: 50px;
	display: table-cell;
	vertical-align: middle;
}

#shop-cart small.personalizaciones {
	display: block;
	font-weight: normal;
}

#topbar #logo {
	margin: 20px 0 5px 0;
	margin-right: 40px;
}
#topbar #logo img {
	height: auto;
}

#topbar .topbar-dropdown {
	text-align: center;
	line-height: 1em;
	float: right;
}
#topbar .topbar-dropdown .title {
	border-left: 0;
	margin-top: 15px;
	color: #d03737;
	padding: 0 15px;
}
#topbar .topbar-dropdown .title a {
	color: #d03737;
}

#finalizar .topbar-dropdown {
	text-align: center;
	line-height: 1em;
	float: right;
}
#finalizar .topbar-dropdown .title {
	border-left: 0;
	margin-top: 12px;
	color: #d03737;
	padding: 0 10px;
}
#finalizar .topbar-dropdown .title a {
	color: #d03737;
}

#finalizar .row.titulo {
	background: #f6f6f6 !important;
	margin-left: -20px;
	margin-right: -20px;
	padding: 10px 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}
#finalizar .row.titulo h3 {
	margin: 0;
}

@media (max-width: 767px) {
	#topbar #logo {
		margin-right: 80px;
	}
	#finalizar .logo img {
		width: 200px;
		float: left;
		margin-left: -10px;
		margin-top: 5px;
	}
	#finalizar .topbar-dropdown .title {
		margin-top: -2px;
		margin-right: -20px;
	}
}

.icon-box.border.color .icon,
.icon-box.border.color .icon i {
	color: #d03737;
	border-color: #d03737;
}

.clear {
	clear: both;
}
a {
	cursor: pointer;
}
select.form-control {
	padding: 10px 18px;
}

i.ico {
	display: inline-block;
	height: calc(30px / 2);
	width: calc(30px / 2);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-bottom: 4px;
	margin-top: 6px;
}
i.ico.usuario {
	width: calc(29px / 2);
	background-image: url("../../images/ico/usuario.png");
}
i.ico.horario {
	width: calc(28px / 2);
	background-image: url("../../images/ico/horario.png");
}
i.ico.whatsapp {
	width: calc(29px / 2);
	background-image: url("../../images/ico/whatsapp.png");
}
i.ico.movil {
	width: calc(18px / 2);
	background-image: url("../../images/ico/movil.png");
}
i.ico.buscar {
	width: calc(29px / 2);
	background-image: url("../../images/ico/buscar.png");
}
i.ico.ayuda {
	width: calc(29px / 2);
	background-image: url("../../images/ico/ayuda.png");
}

.icon-box .icon {
	height: 62px;
	width: 62px;
}
i.ico-portada {
	display: inline-block;
	background-position: center center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
}
i.ico-portada.ico-portada-calendario {
	background-image: url("../../images/ico-portada/calendario.png") !important;
}
i.ico-portada.ico-portada-camion {
	background-image: url("../../images/ico-portada/camion.png") !important;
}
i.ico-portada.ico-portada-corazon {
	background-image: url("../../images/ico-portada/corazon.png") !important;
}
i.ico-portada.ico-portada-euro {
	background-image: url("../../images/ico-portada/euro.png") !important;
}
i.ico-portada.ico-portada-flor {
	background-image: url("../../images/ico-portada/flor.png") !important;
}
i.ico-portada.ico-portada-24h {
	background-image: url("../../images/ico-portada/24h.png") !important;
}

#topbar .cuenta_atras {
	width: 167px;
	height: 56px;
	margin-top: 10px;
	background: url("../../images/cuenta_atras.png");
	line-height: 1em;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px;
	padding-top: 5px;
	margin-left: 30px;
}
#topbar .cuenta_atras span {
	display: block;
	font-size: 20px;
	margin: 6px 0;
	text-transform: none;
}

.cuenta_atras_xs {
	margin: 0 0 5px 0;
	text-align: center;
	background: #d03737;
	color: #fff;
	padding: 5px 0 8px;
	line-height: 1.4em;
}

#mainMenu {
	float: left;
}
#mainMenu > ul > li > a {
	font-size: 13px;
}
#mainMenu .label {
	display: inline-block;
	position: relative;
	top: -5px;
	font-size: 9px;
	font-weight: bold;
	padding: 2px 4px 3px;
	margin-left: 4px;
	background: #d03737 !important;
}
#mainMenu ul li i {
	width: 16px;
}

/*#header { transition: opacity .3s ease, height .4s ease, padding-top .4s ease, line-height .4s ease; -webkit-transition: opacity .3s ease, height .4s ease, padding-top .4s ease, line-height .4s ease; -o-transition: opacity .3s ease, height .4s ease, padding-top .4s ease, line-height .4s ease; }*/

#header {
	border-bottom: 0;
}
#header #buscar {
	padding: 10px;
	height: 60px;
	line-height: 70px;
	cursor: pointer;
}
#header #buscar i {
	font-size: 20px !important;
}
#header #shopping-cart a i {
	width: auto;
	font-size: 26px !important;
}
#header #shopping-cart .shopping-cart-items {
	display: inline-block;
	font-size: 11px;
	padding: 0;
	text-align: center;
	background-color: #d03737;
	color: #fff;
	border-radius: 20px;
	min-width: 22px;
	line-height: 22px;
	height: 22px;
	top: 20px;
	right: -12px;
}
#header.header-sticky #shopping-cart .shopping-cart-items {
	top: 8px !important;
}
#header.header-sticky #buscar {
	line-height: 40px !important;
}

@media (max-width: 767px) {
	body.wide #topbar.topbar-fullwidth > .container {
		padding-left: 15px;
	}
	#header.header-fullwidth .container {
		width: 100% !important;
	}
	#header {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 300 !important;
		background: none !important;
		width: 100%;
		height: 50px !important;
	}
	#header #header-wrap .container {
		padding: 0 !important;
		margin: 0;
	}
	#header #header-wrap .navigation-wrap.navigation-active {
		background: #fff;
		padding: 0 15px;
		margin-top: 95px;
		border-top: 1px solid #eee;
	}
	#header #header-wrap {
		height: 40px !important;
	}
	.nav-main-menu-responsive {
		line-height: 40px !important;
		height: 40px !important;
		margin-right: 10px;
		margin-top: -5px;
	}
}

@media (max-width: 400px) {
	#header #header-wrap .navigation-wrap.navigation-active {
		margin-top: 80px;
	}
}

.nav-main-menu-responsive button {
	display: inline-block;
}
.nav-main-menu-responsive button.extra i {
	position: relative;
	top: 4px;
}
#header .nav-main-menu-responsive #shopping-cart {
	float: none;
	display: inline-block;
	height: 80px;
	position: relative;
	top: 5px;
}
#header .nav-main-menu-responsive #shopping-cart a {
	display: inline-block;
	height: 80px;
}

@media (max-width: 767px) {
	#header #shopping-cart a i {
		font-size: 22px !important;
	}
	#header .nav-main-menu-responsive #shopping-cart a {
		height: 45px;
	}
	#header .nav-main-menu-responsive #shopping-cart {
		height: 45px;
		margin-right: 10px;
	}
	#header #shopping-cart .shopping-cart-items {
		min-width: 18px;
		line-height: 18px;
		height: 18px;
	}
	#mainMenu > ul > li > a {
		height: 50px !important;
		line-height: 30px !important;
	}
}

.modal button.close {
	position: relative;
	top: -10px;
}
#buscador input[type="text"] {
	border-right: 0;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	padding: 10px 18px;
	line-height: 19px;
}
#buscador .btn {
	-webkit-border-top-left-radius: 0px;
	-webkit-border-bottom-left-radius: 0px;
	-moz-border-radius-topleft: 0px;
	-moz-border-radius-bottomleft: 0px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

#footer .footer-content {
	padding-top: 40px;
}
#footer .copyright-content {
	padding: 4px 0;
}
#footer .widget-enlaces p {
	margin-bottom: 0;
}
#footer .widget-enlaces p a::before {
	content: " - ";
}
#footer .widget-enlaces p a:first-child::before {
	content: none;
}

.gototop-button {
	background: #d03737;
	bottom: 5px;
}

@media (max-width: 767px) {
	#header #buscador {
		float: left;
		padding-top: 16px;
		width: 250px;
	}
	#header #buscador .btn {
		height: 46px;
	}
}

section {
	padding: 30px 0;
}
.container-fluid.titulo {
	padding: 60px 0;
	background: url("../img/banner_seccion.jpg");
	background-size: cover;
	background-position: center center;
}
/* .container-fluid.titulo.test { background: url('../img/banner_seccion.jpg') center center; } */

.container-fluid.titulo + section {
	padding-top: 40px;
}
.product-title {
	width: 70%;
}
.product-sale-off.novedad {
	background: #00c0e9;
}
.btn-florclick {
	background-color: #d03737 !important;
	border: 1px solid #d03737 !important;
	color: #fff !important;
}
.btn-florclick:hover {
	background-color: #d03737 !important;
	border: 1px solid #d03737 !important;
}

.buscador-inicio .form-inline .form-group,
.buscador-inicio .form-inline .input-group {
	width: 100%;
}
.buscador-inicio .form-inline select.form-control {
	width: 100%;
	padding: 9px 18px;
	border-right: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.buscador-inicio .form-inline .input-group .input-group-btn {
	width: 105px;
}

.buscador-inicio .form-inline select.form-control,
.buscador-inicio .form-inline button,
.buscador-inicio .form-inline input {
	height: 44px !important;
}

.shop.mosaico .product-image {
}

.product {
	margin-bottom: 30px;
	padding: 6px;
	border-radius: 4px;
	border: 2px solid #fff;
	position: relative;
}
/* .col-md-3 .product.mosaico:hover { border: 2px solid #D03737; } */
.product .product-image img {
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.col-md-3 .product:hover .product-image img {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.product .product-image .badges {
	position: absolute;
	top: 4px;
	right: 4px;
	width: 56px;
}
.product .product-image .badges span {
	display: inline-block;
	width: 56px;
	height: 56px;
	background-size: cover;
	text-indent: -1000px;
	overflow: hidden;
}
.product .product-image .badges span.novedad {
	background-image: url("../../images/novedad.png");
}
.product .product-image .badges span.oferta {
	background-image: url("../../images/oferta.png");
}
.product .product-image .badges span.portes_gratis {
	background-image: url("../../images/portes_gratis.png");
	position: relative;
	left: 2px;
}
.product .product-description {
	color: #594539;
	min-height: 0;
	margin-bottom: 0;
	padding-bottom: 0 !important;
}
.product .product-description h3 {
	color: #594539;
	line-height: 1.4em !important;
	margin-bottom: 4px;
	font-size: 14px;
}
.product .product-description p {
	line-height: 1.4em;
	color: #594539;
	margin-bottom: 0;
	font-size: 12px;
}
.product .product-title {
	float: left;
	margin-bottom: 10px;
}
.product .product-price {
	float: right;
	margin-top: 0;
}
.product .product-price del {
	margin-top: 0;
	color: #594539 !important;
	opacity: 1;
	font-size: 14px;
	margin-bottom: -4px;
}
.product .product-price ins {
	color: #d03737;
	font-size: 18px;
}
.product .comprar {
	display: inline-block;
	background: #d03737;
	color: #fff;
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	padding: 6px 10px;
	border-radius: 10px;
}
.product .ver_mas {
	float: right;
	font-size: 18px;
	color: #594539;
	margin-top: 6px;
}

.product.carrito {
	margin-bottom: 0px !important;
	border: 1px solid #808080;
	border-radius: 0;
	width: 100%;
	cursor: pointer;
}
.product.carrito .product-title,
.product.carrito .product-price {
	float: none;
	width: 100%;
	min-height: 0;
	margin-bottom: 0px !important;
}
.product.carrito .product-title h3 {
	font-size: 13px !important;
}
.product.carrito .product-description {
	width: 100%;
}
.product.carrito .product-price {
	margin: 0 !important;
}
.product.carrito .product-image .badges {
	position: absolute;
	top: 4px;
	right: 4px;
	width: 32px;
}
.product.carrito .product-image .badges span {
	display: inline-block;
	width: 32px;
	height: 32px;
	background-size: cover;
	text-indent: -1000px;
	overflow: hidden;
}

.product.carrito.seleccionado {
	border: 1px solid #d03737;
	background: #d03737;
	color: #fff;
}
.product.carrito.seleccionado h3,
.product.carrito.seleccionado .product-price ins {
	color: #fff;
}

.product .overlay {
	display: none;
}
.product:hover .overlay {
	display: block;
}
.product .overlay,
.product .overlay .transparencia,
.product .overlay .contenido {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
.product .overlay .transparencia {
	background: #fff;
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.product .overlay .contenido {
	display: table;
	text-align: center;
}
.product .overlay .contenido .inner {
	display: table-cell;
	vertical-align: middle;
}
.product .overlay .contenido p {
	font-size: 12px;
	line-height: 1.4em;
	color: #594539;
	margin: 20px;
}
.product .overlay .contenido i.fa {
	font-size: 14px;
}

#product-page .product .product-price ins {
	font-size: 26px;
}
#product-page .product .product-price ins small {
	font-size: 14px;
	display: inline-block;
	color: #594539;
	font-weight: normal;
}
#product-page .product .product-description p {
	font-size: 14px;
	margin-bottom: 10px;
}

.widget-shop .product .product-title h3 a {
	color: #594539;
}
.widget-shop .product .product-title h3 a:hover {
	color: #d03737;
}
.widget-shop .product .product-price {
	line-height: 1em;
}

.product .product-title {
	width: 60%;
}
.product .product-price {
	width: 39%;
}

@media (max-width: 767px) {
	.col-xs-6:nth-child(odd) {
		clear: left;
		padding-left: 5px;
		padding-right: 5px;
	}
	.col-xs-6:nth-child(even) {
		padding-left: 5px;
		padding-right: 5px;
	}
	.product .product-image .badges {
		width: 36px;
	}
	.product .product-image .badges span {
		width: 36px;
		height: 36px;
	}
	/* .product .product-title { width: 65%; }
	.product .product-price { width: 35%; } */
	.product .product-price del {
		font-size: 11px;
		margin-bottom: -9px;
	}
	.product .product-price ins {
		font-size: 16px;
	}
	.product .comprar {
		display: block;
		text-align: center;
		font-size: 14px;
		padding: 4px 0;
	}
	.product .ver_mas {
		display: none;
	}
	.product:hover .overlay {
		display: none !important;
	}
}

.contacto i {
	font-size: 18px;
	margin-right: 5px;
}
#slider .enlace {
	position: absolute;
	bottom: 20px;
	right: 40px;
}
#slider .enlace_slider {
	display: block;
	position: absolute;
	top: 0px;
	left: 80px;
	width: calc(100% - 160px);
	height: calc(100% - 40px);
}
@media (max-width: 767px) {
	#slider .enlace_slider {
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: calc(100% - 25px);
	}
}

@media (max-width: 1240px) {
	#shop-cart {
		padding: 0 20px;
	}
}

.shop-cart .cart-product-price,
.shop-cart .cart-product-subtotal {
	width: 125px;
	text-align: center;
}
.shop-cart .cart-product-quantity {
	width: 120px;
	text-align: center;
}
.shop-cart .info_calculo {
	font-size: 12px;
	margin-top: 10px;
	line-height: 1.4em;
}
.shop-cart .tarjetas .tarjeta {
	cursor: pointer;
	border: 1px solid #ebebeb;
	padding: 3px;
}
.shop-cart .tarjetas .tarjeta span.label {
	position: absolute;
	bottom: 8px;
	right: 8px;
	font-size: 12px;
	line-height: 1.6em;
}
.shop-cart span.label.label-botella {
	font-size: 12px;
	line-height: 1.6em;
}
.shop-cart .tarjetas .tarjeta.seleccionada {
	border: 4px solid #d03737;
	border-radius: 2px;
	padding: 0;
}
.shop-cart .tarjetas p {
	margin: 0 !important;
}
.shop-cart .tarjetas p.caracteres {
	font-size: 11px;
	text-align: right;
}
.shop-cart .tarjetas textarea {
	resize: none;
}

.shop-cart .owl-carousel .owl-controls .owl-nav {
	opacity: 1 !important;
}
.shop-cart .owl-carousel .owl-controls .owl-nav .owl-next {
	right: 0 !important;
}
.shop-cart .owl-carousel .owl-controls .owl-nav .owl-prev {
	left: 0 !important;
}

.shop-cart .table > tbody > tr > td {
	border-bottom: 1px solid #ddd;
	border-top: 0;
}

.shop-cart .finalizar-compra {
	display: block;
	width: 100%;
}
.shop-cart .finalizar-compra strong {
	float: right;
}

.testimonios a {
	color: #333;
}
@media (max-width: 767px) {
	.testimonios .owl-carousel .owl-controls .owl-nav {
		opacity: 1 !important;
	}
	.testimonios .owl-carousel .owl-controls .owl-nav .owl-next {
		right: 0 !important;
	}
	.testimonios .owl-carousel .owl-controls .owl-nav .owl-prev {
		left: 0 !important;
	}
}

.pagar_ahora {
	margin-bottom: 5px;
}
.historial_pedido {
	display: none;
}
.caducidad_cupon {
	font-size: 11px;
	line-height: 1.4em;
	padding-top: 6px;
}
#sin_tarjeta {
	display: inline-block !important;
	width: auto !important;
	margin-left: 10px;
	position: relative;
	top: 1px;
}
#sin_tarjeta label {
	width: auto;
	font-size: 14px;
	color: #696f6f;
}

.finalizar .ac-title {
	text-transform: uppercase;
}
.finalizar .ac-title span {
	color: rgba(44, 47, 59, 0.6);
}
.finalizar .ac-content {
	padding: 15px 17px;
	line-height: 1.4em;
}
.finalizar .fc-toolbar h2 {
	font-size: 24px;
	text-transform: capitalize;
}
.finalizar .fc-toolbar.fc-header-toolbar {
	margin-bottom: 0;
}
.finalizar small {
	line-height: 1.4em;
}
.finalizar .formas_pago i.fa {
	font-size: 20px;
	margin-top: -4px;
	color: #333;
	margin-left: 4px;
}

.fc-event.prohibido {
	background-color: #58575717 !important;
	height: 50px;
	border: 0;
	top: 0px !important;
	position: absolute;
	width: 13%;
}
.fc-event.elegir {
	background: none;
	border: none;
	text-align: center;
	text-transform: uppercase;
	color: #d03737;
	margin: 0 5px;
	padding: 5px 0;
	margin-top: -1px !important;
}
.fc-event.elegir:hover {
	background: #d03737;
	color: white;
}

.fc-event.info {
	background: none;
	border: none;
	text-align: center;
	text-transform: uppercase;
	color: #d03737;
	margin: 0 5px;
	padding: 5px 0 0;
	margin-top: -16px !important;
	font-size: 32px;
}
.fc-event.info:hover {
	color: #d03737;
	background: none;
}
.fc-event.enviar {
	color: #fff !important;
	background: #d03737 !important;
}

.observaciones p {
	margin: 0 !important;
}
.observaciones p.caracteres {
	font-size: 11px;
	text-align: right;
}
.observaciones textarea {
	resize: none;
}

.muchas_gracias {
	padding: 100px 0;
}
.muchas_gracias p {
	text-align: center;
	margin-bottom: 10px;
}

@media (max-width: 767px) {
	.muchas_gracias {
		padding: 20px 0 !important;
	}
}

.instrucciones_recuperar {
	font-size: 14px;
	font-weight: normal;
	line-height: 1.4em;
}

.page-title {
	line-height: 1.4em;
}
.container-fluid.titulo {
	padding: 40px 0;
}

@media (max-width: 767px) {
	.container-fluid.titulo {
		padding: 30px 20px !important;
	}
	.page-title h1 {
		font-size: 22px;
	}
	.boton_actualizar_carrito {
		margin-top: -25px !important;
	}
	.deslizar {
		font-size: 11px;
		margin-bottom: 0;
		text-align: center;
	}
	.deslizar_calendario {
		margin-top: -15px;
		margin-bottom: 10px;
	}
	.boton_xs_movil {
		padding: 6px 8px !important;
		margin: 0;
	}
	.sweet-alert h2 {
		font-size: 20px;
	}
}

.section_producto + .section_producto {
	padding-top: 0px;
	margin-top: -30px;
}
.btn-historial-pedidos {
	position: relative;
	top: 5px;
}

label.mensaje_botella {
	font-weight: normal;
	font-size: 12px;
	color: #696f6f;
}
label.mensaje_botella input {
	position: relative;
	top: 2px;
}

.texto-inicio h1 {
	font-size: 32px;
	line-height: 38px;
	text-align: center;
	margin-bottom: 20px;
}
.texto-inicio h2 {
	font-size: 28px;
	text-align: center;
	margin-bottom: 20px;
}
.texto-inicio p + h2 {
	margin-top: 50px;
}
.texto-inicio p.lead {
	font-size: 16px;
	line-height: 1.4em;
}
.texto-inicio strong,
.quienes-somos strong {
	font-weight: 500;
	color: #333;
}

.quienes-somos .full-size {
	width: 100%;
}
.quienes-somos p.lead {
	font-size: 16px;
	line-height: 1.4em;
}

.blog.heading:after {
	margin: 0;
	width: 0;
	border-top: 0;
	display: none;
}

#inicio h1 {
	font-size: 26px;
	font-weight: 300;
	line-height: 1;
	margin: 15px 0 10px;
}
#inicio .heading h3 {
	font-size: 18px;
	line-height: 20px;
}
#inicio .heading-fancy h3 {
	font-size: 18px;
	font-weight: 600;
}
#inicio h5 {
	font-weight: 600;
	font-size: 18px;
}
.widget-shop .product .product-title h4,
.widget-shop .product .product-title h4 a {
	font-size: 15px !important;
}
.product .product-description h4 {
	color: #594539;
	line-height: 1.4em !important;
	margin-bottom: 4px;
	font-size: 14px !important;
}

p.producto_no_disponible {
	font-weight: normal;
	margin-bottom: 10px;
}

p.producto_no_disponible strong {
	font-weight: bold;
	color: #d03737;
}

.display_none {
	display: none;
}
html,
body {
	visibility: visible;
}

.mosaico_submenu {
	margin-bottom: 30px;
}
.sidebar-modern:before {
	background: #fff !important;
}
.sidebar-inv .widget-title {
	text-align: left;
}
.sidebar-inv .widget-title::after {
	display: none;
}
.sidebar-inv .list-arrow-icons li {
	padding-left: 14px;
	text-align: left;
}
.sidebar-inv .list-arrow-icons li.activo {
	background: #d03737;
	color: #fff;
	border-radius: 2px;
}
.sidebar-inv .list-arrow-icons li.activo a {
	color: #fff;
}
.sidebar-inv .list-arrow-icons li.subactivo a {
	color: #d03737;
}
.sidebar-inv .list-arrow-icons li.subactivo a:before {
	color: #d03737;
	content: "\f105";
	font-family: fontawesome;
	float: right;
	margin-right: 6px;
}
.sidebar-inv .list-arrow-icons li:before {
	margin-left: -12px;
	margin-right: 10px;
	position: absolute;
	font-family: fontawesome;
	content: "\f105";
}
.sidebar-inv .list-arrow-icons li.activo:before {
	color: #d03737;
}
.sidebar-inv .list-arrow-icons li.activo:after {
	content: "\f105";
	font-family: fontawesome;
	float: right;
	margin-right: 6px;
}
.sidebar-inv .list-arrow-icons li.submenu {
	display: none;
}
.sidebar-inv .list-arrow-icons li.submenu:before {
	content: none;
}
.sidebar-inv .list-arrow-icons li.submenu.separador {
	line-height: 10px;
}

@media (max-width: 767px) {
	.mosaico .productos .menu {
		margin-top: -20px;
	}
	.mosaico .productos .clear {
		clear: none !important;
	}
	.mosaico .productos .clear_movil {
		clear: both;
	}
	.mosaico .productos .col-xs-6 {
		clear: none !important;
	}
    .mosaico.una-columna .col-xs-6 {
        width: 100% !important;
    }
}

.llamar_whatsapp {
	border-radius: 4px 0 0 4px;
	bottom: 50px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	line-height: 41px;
	width: 42px;
	text-align: center;
	padding: 0px;
	position: fixed;
	right: 0;
	z-index: 199;
	font-size: 24px;
	color: #fff;
	background: #4dc247;
}
.llamar_whatsapp:hover {
	background: #6aea63;
	color: #fff;
}

.supermolon {
	border-radius: 4px 0 0 4px;
	bottom: 95px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	line-height: 41px;
	width: 42px;
	text-align: center;
	padding: 0px;
	position: fixed;
	right: 0;
	z-index: 199;
	font-size: 24px;
	color: #fff;
	background: #98c5ca;
}
.supermolon img {
	width: 80%;
	position: relative;
	top: -3px;
}
.supermolon:hover {
	background: #cfe4e6;
}

.popup-supermolon {
	display: inline-block;
	border-radius: 4px 4px 0 0;
	position: fixed;
	bottom: -200px;
	right: 50px;
	z-index: 220;
	overflow: hidden;
	transition: all 0.25s linear;
}
.popup-supermolon.activo {
	transform: translateY(-200px);
}
.popup-supermolon-movil {
	display: none;
}

@media (max-width: 767px) {
	.popup-supermolon {
		display: none;
	}
	.popup-supermolon-movil {
		display: inline-block;
		position: fixed;
		bottom: -100px;
		left: 0;
		z-index: 220;
		width: 100%;
		overflow: hidden;
		transition: all 0.25s linear;
		border-top: 2px solid #fff;
	}
	.popup-supermolon-movil img {
		width: 100%;
	}
	.popup-supermolon-movil.activo {
		transform: translateY(-100px);
	}
}

.info_suscripcion p {
	font-size: 18px;
	color: #000;
}
.info_suscripcion img {
	width: 100%;
	margin-bottom: 10px;
}
.info_suscripcion p a {
	color: #d03737;
}

.texto_seo {
	font-size: 12px;
	line-height: 1.4em;
	color: #594539 !important;
}
.texto_seo h2,
.texto_seo h3 {
	font-size: 22px;
	margin: 10px 0 10px 0;
	color: #594539 !important;
}
.texto_seo p {
	margin: 0 0 10px 0;
	color: #594539 !important;
	font-size: 12px;
}

@media (max-width: 767px) {
	.menu-destacado {
		position: relative;
		top: -5px;
	}
}

.error_404 {
	padding: 100px 0 120px;
}

.error_404 p {
	font-size: 20px;
	color: #111;
	font-weight: 100;
}

.error_404 a {
	font-size: 36px;
	color: #d03737;
	font-weight: 300;
}

@media screen and (max-width: 768px) {
	#topbar,
	#header {
		position: fixed;
		top: 0;
		left: 0;
		border: 0;
	}

	#topbar {
		z-index: 400 !important;
	}

	#header {
		z-index: 399 !important;
		width: 100%;
		min-height: 84px;
		background: #fff !important;
		border-bottom: 1px solid #eee;
	}

	#topbar #logo {
		margin-right: 0;
	}

	#topbar #logo img {
		max-height: 50px;
	}

	body.wide #topbar.topbar-fullwidth > .container {
		width: auto !important;
	}

	#header #header-wrap .navigation-wrap.navigation-active {
		margin-top: 83px;
		background: #fff;
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px;
		width: calc(100% + 30px);
		position: relative;
	}

	.nav-main-menu-responsive {
		height: 84px;
	}

	.main-wrapper {
		padding-top: 84px;
	}

	#topbar .cuenta_atras {
		display: none !important;
	}
}
@media screen and (max-width: 991px) {
	#header #header-wrap .navigation-wrap.navigation-active {
		margin-top: 83px;
		background: #fff;
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px;
		width: calc(100% + 30px);
		position: relative;
	}
}

@media screen and (max-width: 767px) {
	#header #header-wrap .navigation-wrap.navigation-active {
		margin-left: auto;
		margin-right: auto;
		width: auto;
	}
}

#product-page .opcion {
	display: inline-block;
	width: 120px;
	border: 1px solid #808080;
	color: #808080;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	margin-right: 5px;
}

#product-page .opcion i {
	display: inline-block;
	background: url("../../images/gris.svg") center center no-repeat;
	background-size: contain;
	width: 25px;
	height: 35px;
	margin: 0;
}

#product-page .opcion p,
#product-page .opcion .precio {
	color: #808080 !important;
	margin-bottom: 2px !important;
	line-height: 1em;
}

#product-page .opcion .precio {
	font-weight: bold;
	margin-bottom: 0px !important;
}

#product-page .opcion:hover {
	border: 1px solid #d03737;
	color: #d03737;
}

#product-page .opcion:hover p,
#product-page .opcion:hover .precio,
#product-page .opcion:hover small {
	color: #d03737 !important;
}

#product-page .opcion:hover i {
	background: url("../../images/rojo.svg") center center no-repeat;
	background-size: contain;
}

#product-page .opcion.seleccionado {
	border: 1px solid #d03737;
	background: #d03737;
	color: #fff;
}

#product-page .opcion.seleccionado p,
#product-page .opcion.seleccionado .precio,
#product-page .opcion.seleccionado small {
	color: #fff !important;
}

#product-page .opcion.seleccionado i {
	background: url("../../images/blanco.svg") center center no-repeat;
	background-size: contain;
}

@media (max-width: 767px) {
	#product-page .container {
		padding: 0 10px;
	}

	#product-page .opcion {
		width: 92px;
		margin-right: 2px;
		float: left;
		padding: 5px;
	}

	#product-page .opcion i {
		width: 20px;
		height: 28px;
	}

	#product-page .opcion p,
	#product-page .opcion .precio {
		font-size: 12px !important;
	}
}

#finalizar .nota {
	font-style: normal;
	color: #d03737;
}

#finalizar .direcciones_guardadas label {
	display: block;
	font-weight: normal;
}

#finalizar .direccion_seleccionada {
	border: 1px solid #ddd;
	padding: 10px;
	display: flex;
	flex-direction: row;
}

#finalizar .direccion_seleccionada .descripcion {
	flex-grow: 1;
}

#finalizar .direccion_seleccionada .descripcion .nombre {
	font-weight: bold;
}

#finalizar .direccion_seleccionada .editar {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

#finalizar .direccion_seleccionada .editar i {
	font-size: 34px;
}

.form-group label span {
	font-weight: normal;
	text-transform: none;
}

#finalizar .dia_entrega .fechas {
	display: flex;
	flex-direction: row;
}

#finalizar .dia_entrega .fechas .fecha {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	border: 1px solid #ddd;
	padding: 10px;
	margin: 0 10px;
	cursor: pointer;
}

#finalizar .dia_entrega .fechas .fecha:hover {
	border: 1px solid #d03737;
	color: #d03737;
}

#finalizar .dia_entrega .fechas .fecha:hover div {
	color: #d03737;
}

#finalizar .dia_entrega .fechas .fecha.seleccionada {
	border: 1px solid #d03737;
	background: #d03737;
	color: #fff;
}

#finalizar .dia_entrega .fechas .fecha.seleccionada div {
	color: #fff;
}

#finalizar .dia_entrega .fechas .fecha.otro .fecha_seleccionada {
	color: #fff !important;
	font-size: 12px;
	display: none;
}

#finalizar .dia_entrega .fechas .fecha.otro .precio {
	color: #fff !important;
	display: none;
}

#finalizar .dia_entrega .fechas .fecha.otro.seleccionada .fecha_seleccionada,
#finalizar .dia_entrega .fechas .fecha.otro.seleccionada .precio {
	display: inline-block;
}

#finalizar .dia_entrega .fechas .fecha:first-child {
	margin-left: 0;
}

#finalizar .dia_entrega .fechas .fecha:last-child {
	margin-right: 0;
}

#finalizar .dia_entrega .fechas .fecha .nombre {
	font-style: italic;
	font-size: 16px;
	font-weight: 100;
	text-align: center;
}

#finalizar .dia_entrega .fechas .fecha .dia {
	font-size: 12px;
	margin: -2px 0;
	text-align: center;
}

#finalizar .dia_entrega .fechas .fecha .precio {
	font-weight: bold;
	font-size: 16px;
	text-align: center;
}

#finalizar .dia_entrega .fechas .fecha.otro i {
	font-size: 20px;
}

#finalizar .dia_entrega .nota {
	display: none;
}

#finalizar .dia_entrega.disabled .nota {
	display: block;
}

#finalizar .dia_entrega.disabled .fechas .fecha {
	color: #ddd !important;
	border: 1px solid #ddd !important;
	background: #fff !important;
	cursor: default;
}

#finalizar .dia_entrega.disabled .fechas .fecha div {
	color: #ddd !important;
}

#finalizar .dia_entrega.disabled .calendario {
	display: none !important;
}

#finalizar .finalizar-compra {
	display: block;
	width: calc(100% - 20px);
	margin-left: 15px !important;
}
#finalizar .finalizar-compra strong {
	float: right;
}

#finalizar .volver .finalizar-compra {
	display: inline-block;
	width: calc(100% - 500px);
	margin-left: 15px !important;
}
#finalizar .volver .finalizar-compra.btn-volver {
	width: 130px;
	text-align: center !important;
}

#finalizar .btn-disabled {
	background-color: #e69494 !important;
	border-color: #e69494 !important;
}

#finalizar.resumen_final span.fecha_entrega,
#finalizar.resumen_final span.horario_entrega {
	text-transform: capitalize;
	color: #d03737;
}
.container-box-carrito {
	position: absolute;
	width: 60%;
	top: 15px;
	right: 0;
	margin: 0 5%;
}
.box-carrito {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px dashed lightgray;
	height: 50px;
	border-radius: 5px;
}

.box-text {
	font-weight: lighter;
	color: lightgray;
}
.box-carrito-selected {
	background-color: #d03737;
	border: 1px solid #d03737;
	border-radius: 5px;
}

.box-carrito-selected span {
	font-weight: 600;
	color: white;
}
.text-select-carrito {
	font-weight: lighter;
}
#box-text-xs {
	display: none;
}

.shop-cart .tarjetas .tarjeta:hover {
	border: 2px solid #d03737;
}
.product.mosaico.carrito.complemento:hover {
	border: 1px solid lightgray;
}
.shop-cart .product.mosaico.carrito.complemento:hover {
	border: 3px solid #d03737;
}

.truncate {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ligtherText {
	font-weight: 100 !important;
}

.shop-cart .table th {
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.shop-cart .owl-item.active {
		margin-right: 5px !important;
	}
	.container-box-carrito {
		position: relative;
		width: 90%;
		top: 10px;

		margin: 0 5%;
	}

	.box-carrito {
		height: 2px;
	}
	.box-text {
		display: none;
	}
	#box-text-xs {
		display: block;
		margin-top: 10px;
		font-weight: lighter;
		font-size: 20px;
		color: #d03737;
		margin-bottom: 0;
	}
	.product.carrito .product-description {
		padding: 0;
	}
	.product.carrito .product-title h3 {
		font-size: 10px !important;
	}
	.product.carrito .product-price {
		margin-top: -10px !important;
	}
	.product.carrito .product-price ins {
		font-weight: lighter;
		color: #d03737;
		font-size: 14px;
	}

	.shop-cart .table-responsive {
		font-weight: 100 !important;
		border: 0px solid red !important;
	}
	.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name {
		width: 50px;
	}
	.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name > div {
		height: 12px;
		display: block;
		position: relative;
		width: 200px;
		left: -5px;
		top: 0px;
		font-size: 10px;
	}

	.fc-event.enviar,
	.fc-event.elegir {
		font-size: 9px;
	}

	#finalizar .dia_entrega .fechas .fecha {
		margin: 0 2px;
		padding: 5px;
		line-height: normal;
	}
	#finalizar .dia_entrega .fechas .fecha .nombre {
		font-size: 12px;
		margin: 5px 0;
	}

	#finalizar .dia_entrega .fechas .fecha .precio {
		margin: 5px 0;
	}

	#finalizar .dia_entrega .fechas .fecha .nombre {
		font-size: 12px;
		margin: 5px 0;
	}
}

#shop-cart section.oculto {
	display: none;
}

#cuenta_atras {
	font-size: 1.2em !important;
	font-weight: bold;
	text-transform: lowercase !important;
}

.buscador-inicio {
	overflow: visible !important;
}

.buscador-inicio .dropdown button {
	background-color: white;
	font-family: "Roboto", sans-serif !important;
	letter-spacing: 0 !important;
}

.buscador-inicio .dropdown-item {
	display: block;
	font-weight: lighter;
	padding: 5px 10px;
	text-transform: uppercase;
}

.buscador-inicio .selected-item {
	font-weight: bold;
	color: #d03737;
}

section.cuidados_composicion h3 {
	font-weight: 100;
	font-size: 24px;
	text-transform: uppercase;
}

section.cuidados_composicion .composicion {
	display: none;
}

section.cuidados_composicion .composicion .item {
	padding-bottom: 15px;
	border-bottom: 1px solid #594539;
	margin-bottom: 15px;
}

section.cuidados_composicion .composicion .item:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

section.cuidados_composicion .composicion .item img {
	float: left;
	margin-right: 10px;
}

section.cuidados_composicion .composicion .item div.nombre,
section.cuidados_composicion .composicion .item div.resumen {
	color: #594539;
	font-weight: 100;
	line-height: 1.2em;
}

section.cuidados_composicion .composicion .item div.nombre {
	font-weight: bold;
	margin-bottom: 5px;
	padding-top: 10px;
}

section.cuidados_composicion .composicion .item div.nombre span {
	font-size: 24px;
}

section.cuidados_composicion .composicion .item div.resumen strong {
	font-weight: normal;
}

section.cuidados_composicion .cuidados .item {
	margin-bottom: 15px;
}

section.cuidados_composicion .cuidados .item:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

section.cuidados_composicion .cuidados .item img {
	float: left;
	margin-right: 10px;
	width: 60px;
}

section.cuidados_composicion .cuidados .item div.nombre,
section.cuidados_composicion .cuidados .item div.resumen {
	color: #594539;
	font-weight: 100;
	line-height: 1.2em;
}

section.cuidados_composicion .cuidados .item div.nombre {
	text-transform: uppercase;
}

section.cuidados_composicion .cuidados .item div.resumen strong {
	font-weight: normal;
}

section.cuidados_composicion .cuidados .item .dificultad {
	color: #d03737;
	font-size: 22px;
}
#finalizar .logo img {
	width: 200px;
	margin-top: -15px;
}
#finalizar section {
	padding: 20px 0 !important;
}

.menu-destacado {
	font-size: 11px;
}

.background-red {
	background-color: #d03737;
}
.background-red,
.background-red h1,
.background-red h2,
.background-red h3,
.background-red h4,
.background-red a,
.background-red p {
	color: white !important;
}
.background-red h4 {
	font-weight: lighter;
	text-transform: uppercase;
}
.background-red .text-content {
	font-weight: 500;
	line-height: 1.5;
}
.background-red .copyright-content {
	border: 0px solid transparent !important;
	min-height: 50px !important;
}

#carrito-home {
	display: none;
	width: 390px;
	min-height: 300px;
	background-color: white;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(208, 55, 55, 0.65);
	-moz-box-shadow: 0px 0px 20px 0px rgba(208, 55, 55, 0.65);
	box-shadow: 0px 0px 20px 0px rgba(208, 55, 55, 0.65);
	position: fixed;
	right: 10px;
	top: 0px;
	z-index: 342534;
}

.title-shopcart {
	margin: 25px 0 15px 0px;
	text-align: center;
	font-size: 24px;
}

.totals-shopcart {
	margin-top: 5px;
	font-weight: 500;
}

.personalizacion-shopcart {
	font-weight: 500;
	margin-top: -10px;
	margin-bottom: -10px;
}

.line-shopcart {
	margin: 2px;
	padding: 5px 0px;
	border-top: 1px solid whitesmoke;
}

@media (max-width: 767px) {
	#carrito-home {
		width: 100%;
		right: 0;
	}

	.col-xs-8 {
		width: 66.66666667% !important;
	}
}

#cookies {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.6);
	align-items: end;
	justify-content: end;
	z-index: 999 !important;
}

#cookies > div {
	width: calc(100vw - 40px);
	max-width: 680px;
	background: #fff;
	border-radius: 5px;
	padding: 20px 20px 10px 20px;
	margin: 20px;
}

#cookies .botones {
	display: flex;
}

@media (max-width: 767px) {
	
	#cookies .botones {
		flex-direction: column;
		padding: 10px 0;
	}

	#cookies .botones button {
		margin: 10px 0 0 0 !important;
	}

}

#cookies p {
	font-size: 16px;
	line-height: 1.4em;
	margin: 0;
	text-align: left;
}
#cookies a {
	color: #d03737;
}
#cookies label {
	display: block;
}

#cookies .configuracion {
	display: none;
}

.modal-title.buscar {
	font-weight: 600;
	font-size: 18px;
	color: #111;
}

.page-title .h1 {
    display: block;
    font-size: 42px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-left: -2px;
    margin-top: -4px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif !important;
    color: #111;
}

h1.blog {
    font-size: 32px;
    line-height: 1.2em;
}
.shop-cart label,
#dedicatoria textarea,
.shop-cart #resumen textarea {
	font-weight: lighter;
}
.shop-cart input,
.shop-cart select,
.shop-cart #resumen {
	font-weight: 500;
}
.icon-box.small > h3 {
	line-height: 1.1;
}


.shop-cart #resumen .resumen_pedido .direccion_seleccionada .descripcion {
	font-weight: lighter;
}
#finalizar .direccion_seleccionada .descripcion .nombre {
	font-weight: 500;
}

.icon-box.small > p {
	font-weight: lighter;
}
.shop-cart .horario > p {
	font-weight: 100;
}

.shop-cart .resumen .resumen_producto_nombre {
	font-weight: lighter;
}
.shop-cart .resumen .resumen_producto_cantidad {
	font-size: 20px;
	position: relative;
	top: 2px;
	margin-right: 5px;
	font-weight: 500;
}

.shop-cart .cart-product-name .amount {
	position: relative;
	top: 1px;
	font-weight: 500;
}

.shop-cart .buscar_direccion_info {
	font-weight: 500;
}

.shop-cart .aviso_carrito_calendario {
	border: 1px solid #d03737;
	/* color: #fff;  */
	padding: 10px;
	border-radius: 4px;
	font-weight: 100;
	text-transform: uppercase;
}

.shop-cart .finalizar-compra.amount,
.shop-cart .finalizar-compra.btn-volver {
	padding-top: 15px;
	padding-bottom: 15px;
}
.shop-cart .finalizar-compra.btn-volver {
	float: left;
	margin-left: 15px !important;
	
}
.shop-cart #entrega input[type=text]{
	height: 42px;
	padding-left:5px;
	padding-right:0px;

}
.shop-cart .form-group {
	margin-bottom: 8px;
}
.shop-cart .buscar_direccion_info{
	font-size: 12px;
	text-transform:none ;
	margin-bottom: -10px;
	color:gray;
}
#finalizar .horarios {
    display: flex;
    flex-direction: row;
}

#finalizar  .horarios .hor.seleccionada {
    border: 1px solid #D03737;
    background: #D03737;
    color: #fff;
}

#finalizar .horarios .hor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 10px 0;
    cursor: pointer;
}

#finalizar .horarios .hor:first-child {
	margin-left: 0;
}

#finalizar .horarios .hor:last-child {
	margin-right: 0;
}

#finalizar .horarios .hor input {
	display: none;
}

#finalizar .horarios .hor:hover{
	border: 1px solid #D03737;
}

#finalizar  .horarios .hor.disabled {
    background-color: #58575717;
	color: #58575780;
	border: 1px solid #ddd !important;
}

#finalizar .horarios .hor .hor_title{
	text-transform: uppercase;
	font-weight: lighter;
}
#finalizar .horarios .hor .hor_time{
	font-weight: 500;
	margin-top: -5px;
}
#finalizar .horarios .hor .hor_price{
	margin-top: -5px;
	font-weight: bold;
}
.obligatorio_on{
	border:1px solid  #D03737 !important;
}

@media (max-width: 767px) {
	#icon_volver_florclick img {
		position: absolute;
		left: 20px;
		top: 8px;
		width: 80px;
	}
	.shop-cart h2,
	.shop-cart h3 {
		text-align: center;
		font-weight: lighter;
		font-size: 18px !important;
	}
	.shop-cart h4 {
		text-align: center;
		font-size: 14px !important;
		font-weight: 500;
		margin: 10px 0;
	}
	.shop-cart .clear_none {
		clear: none;
	}
	.shop-cart .col-xs-8 {
		width: 66.66666667% !important;
	}
	.shop-cart .col-xs-2 {
		width: 16.66666667% !important;
	}
	.shop-cart .col-xs-4 {
		width: 33.33333333% !important;
	}

	
	.shop-cart .form-group {
		margin-bottom: -5px;
		margin-top: 0px;
	}
	.shop-cart .col-xs-6 {
		padding-left: 15px;
		padding-right: 15px;
	}
	.shop-cart .finalizar-compra.amount {
		padding-left: 8px;
		padding-right: 8px;
	}
	#finalizar .volver .finalizar-compra {
		display: inline-block;
		width: calc(100% - 160px);
		margin-left: 15px !important;
	}
}
